export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_FAILED = 'PRODUCTS_FAILED';

export const ADD_SLIDES_IMAGES = 'ADD_SLIDES_IMAGES';
export const SLIDES_IMAGES_LOADING = 'SLIDES_IMAGES_LOADING';
export const SLIDES_IMAGES_FAILED = 'SLIDES_IMAGES_FAILED';

export const ADD_SERVICES = 'ADD_SERVICES';
export const SERVICES_LOADING = 'SERVICES_LOADING';
export const SERVICES_FAILED = 'SERVICES_FAILED';

export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_FAILED = 'CATEGORIES_FAILED';

export const ADD_CATEGORY_DETAIL = 'ADD_CATEGORY_DETAIL';
export const CATEGORY_DETAIL_LOADING = 'CATEGORY_DETAIL_LOADING';
export const CATEGORY_DETAIL_FAILED = 'CATEGORY_DETAIL_FAILED';