import React from 'react';
import Slide from './SlideComponent';
import { Link, NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const imgStyles = {
	width: "100%",
	height: "100%",
	borderRadius: "20px"
}

function Home(props) {
	return(
		<React.Fragment>
			<div>
				<Slide slides={props.slides}
					isLoading={props.slidesLoading}
					errMess={props.slidesErrMess} />
			</div>
			<section className="parallax" id="section-1">
				<div className="container">
					<div className="row row-content">
						<Fade left>
							<div className="col-12 col-md-6 col-lg-6">
								<img src="/assets/images/home/pexels-kateryna-babaieva-3181187.jpg" alt="Content-1" style={imgStyles} />
							</div>
							<div className="col-12 col-md-6 col-lg-6">
								<h1 style={{fontWeight: "bold"}}>Nosotros</h1>
								<p style={{textAlign: "justify"}}>
									Aconel es una empresa dedicada a la compra-venta de productos de automatización industrial y ofrece servicios de soporte y mantenimiento técnico de maquinaria industrial.
								</p>
							</div>
						</Fade>
					</div>
					<div className="row row-content">
						<Fade right>
							<div className="col-12 col-md-6 col-lg-6">
								<h1>Misión</h1>
								<p>
									La misión de aconel es ser una empresa líder en la comercialización de productos de automatización, satisfaciendo las necesidades de nuestro clientes, ofreciéndoles productos y servicios de calidad ofrecemos soluciones personalizadas. Automatización Industrial, Tableros Eléctricos, Mantenimiento de lavadoras industriales, mantenimiento y reparación de calderos.
									Nuestro trabajo es contribuir con calidad en la ejecución de sus proyectos e ideas de mejoramiento, así como también, ser el instrumento de apoyo y descarga de actividades complementarias a su producción.
								</p>
							</div>
							<div className="col-12 col-md-6 col-lg-6">
								<img src="/assets/images/home/pexels-pixabay-159298.jpg" alt="Content-1" style={imgStyles} />
							</div>
						</Fade>
					</div>
				</div>
			</section>
			<section className="parallax" id="section-2">
				<div className="container">
					<div className="row row-content">
						<Fade left>
							<div className="col-12 col-md-6 col-lg-6">
								<img src="/assets/images/home/pexels-pixabay-257736.jpg" alt="Content-1" style={imgStyles} />
							</div>
							<div className="col-12 col-md-6 col-lg-6 services-productos">
								<h1 style={{fontWeight: "bold"}}>Conoce nuestros productos y servicios</h1>
								<div className="row justify-content-center">
									<div className="col-12 col-sm-4 col-md-4 col-lg-4 d-flex align-items-center justify-content-center" align="center">
										<Link to="#">
											<figure>
												<img src="/assets/images/home/automatizacion.png" alt="Automatización" style={{width: "120px", height: "auto"}} />
												<figcaption>Automatización Industrial</figcaption>
											</figure>
										</Link>
									</div>
									<div className="col-12 col-sm-4 col-md-4 col-lg-4 d-flex align-items-center justify-content-center" align="center">
										<Link to="#">
											<figure>
												<img src="/assets/images/home/relampago.png" alt="Instalaciones Eléctricas" style={{width: "120px", height: "auto"}} />
												<figcaption>Instalaciones Eléctricas</figcaption>
											</figure>
										</Link>
									</div>
									<div className="col-12 col-sm-4 col-md-4 col-lg-4 d-flex align-items-center justify-content-center" align="center">
										<Link to="#">
											<figure>
												<img src="/assets/images/home/led.png" alt="Tablero" style={{width: "120px", height: "auto"}} />
												<figcaption>Tableros Eléctricos</figcaption>
											</figure>
										</Link>
									</div>
								</div>
								<div className="row d-flex justify-content-center">
									<div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center justify-content-center" align="center">
										<Link to="#">
											<figure>
												<img src="/assets/images/home/lavadora.png" alt="Lavadoras" style={{width: "120px", height: "auto"}} />
												<figcaption>Mantenimiento de Lavadoras Industriales</figcaption>
											</figure>
										</Link>
									</div>
									<div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center justify-content-center" align="center">
										<Link to="#">
											<figure>
												<img src="/assets/images/home/caldera.png" alt="Caldera" style={{width: "120px", height: "auto"}} />
												<figcaption>Mantenimiento de Calderos</figcaption>
											</figure>
										</Link>
									</div>
								</div>
							</div>
						</Fade>
					</div>
					{/* <div className="row row-content">
						<div className="col-12" align="center">
							<h1 style={{fontWeight: "bold"}} align="center">Proyectos Destacados</h1>
						</div>
					</div> */}
				</div>
			</section>
			<div className="container">
				<div className="row row-content">
					<div className="col-6 col-md-3 d-flex justify-content-center mb-3">
						<img src="assets/images/Siemens-Logo.png" width="150" height="60" alt="Siemens"/>
					</div>
					<div className="col-6 col-md-3 d-flex justify-content-center mb-3">
						<img src="assets/images/marcas/schneider750x300.png" width="150" height="60" alt="Schneider"/>
					</div>
					<div className="col-6 col-md-3 d-flex justify-content-center mb-3">
						<img src="assets/images/marcas/chint750x300.png" width="150" height="60" alt="Chint"/>
					</div>
					<div className="col-6 col-md-3 d-flex justify-content-center mb-3">
						<img src="assets/images/marcas/autonics750x300.png" width="150" height="60" alt="Autonics"/>
					</div>
				</div>
				<div className="row row-content-without-border">
					<div className="col-12 d-flex justify-content-center">
						<NavLink className="btn btn-dark" to="/contactus">Cotizar</NavLink>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Home;