import React, { Component } from 'react';
import { Nav, NavItem, Navbar, NavbarToggler, NavbarBrand, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Loading } from './LoadingComponent';
import '../styles/Nav.css';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isNavOpen: false,
			sticky: 0
		};
		this.toggleNav = this.toggleNav.bind(this);
	}

	toggleNav() {
		this.setState({
			isNavOpen: !this.state.isNavOpen
		});
	}

	onScroll = (header) => (event) => {
		if (window.pageYOffset - 100 > this.state.sticky) {
			header.classList.add('sticky');
		} else {
			header.classList.remove("sticky");
		}
	}

	componentDidMount() {
		const header = document.getElementById("aconel-header");
		this.setState({
			sticky: header.offsetTop
		});
		window.addEventListener('scroll', this.onScroll(header));
	}

	componentWillUnmount () {
		window.removeEventListener('scroll', this.onScroll);
	}


	render() {
		return(
			<Navbar dark expand="md" id="aconel-header" className="header">
				<div className="container">
					<NavbarBrand className="mr-auto brand" href="/">
		            	<img src='/assets/images/aconel.png' width="150" height="50"
		            		alt="Aconel" />
		            </NavbarBrand>
					<NavbarToggler onClick={this.toggleNav} />
		            <Collapse isOpen={this.state.isNavOpen} navbar>
		            	<Nav navbar>
		            		<NavItem className="mr-3">
		            			<NavLink className="nav-link" to="/home">
		            				<span className="fa fa-home fa-lg"></span> Inicio
		            			</NavLink>
		            		</NavItem>
		            		<NavItem className="mr-3">
		            			<NavLink className="nav-link" to="/aboutus">
		            				Quiénes somos
		            			</NavLink>
		            		</NavItem>
		            		<UncontrolledDropdown nav inNavbar>
			            		<DropdownToggle nav caret>
			            			Productos
			            		</DropdownToggle>
			            		<DropdownMenu className='bg-dark-aux'>
			            			<RenderCategories categories={this.props.categories}
			            				isLoading={this.props.categoriesLoading}
			            				errMess={this.props.categoriesErrMess} />
			            		</DropdownMenu>
		            		</UncontrolledDropdown>
		            		<NavItem className="mr-3">
		            			<NavLink className="nav-link" to="/services">
		            				Servicios
		            			</NavLink>
		            		</NavItem>
		            		<NavItem className="mr-3">
		            			<NavLink className="nav-link" to="/contactus">
		            				Contáctenos
		            			</NavLink>
		            		</NavItem>
		            	</Nav>
		            </Collapse>
				</div>
			</Navbar>
		);
	}
}

function RenderCategories(props) {
	if (props.isLoading) {
		return(
			<DropdownItem>
				<Loading />
			</DropdownItem>
		);
	} else if (props.errMess) {
		return(
			<DropdownItem>
				props.errMess
			</DropdownItem>
		);
	} else {
		const categories = props.categories.map(item => {
			return(
				<DropdownItem key={item.id}>
    				<NavLink className="nav-link" to={'/categories/' + item.slug}>
        				{item.name}
        			</NavLink>
    			</DropdownItem>
			);
		});

		return (categories);
	}
}

export default Header;